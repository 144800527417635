<template>
  <div id="app">

    <a-button id="logout" type="dashed" @click="logout">
      Logout
    </a-button>

    <a-tabs v-model:activeKey="tabKey">
      <a-tab-pane key="1" tab="Notifications">
        <TaskNotification :keycloak='keycloak'/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Tasks">
        <TaskQuery :keycloak='keycloak'/>
      </a-tab-pane>
    </a-tabs>

  </div>

</template>

<script>


import TaskNotification from "@/components/TaskNotification.vue";
import TaskQuery from "@/components/TaskQuery.vue";
import {authEnabled} from "@/config/api";

export default {
  name: 'app',
  components: {
    TaskNotification,
    TaskQuery
  },
  props: ['keycloak'],
  data() {
    return {
      tabKey: "1",
    }
  },
  created() {
    this.tabKey = "2"
  },
  mounted() {
    this.tabKey = "1"
  },
  methods: {
    logout() {
      if (authEnabled)
        this.keycloak.logout()
    }
  }

}
</script>


<style>

.beamStatusConnected {
  color: blue
}

.beamStatusDisconnected {
  color: red
}

#app {
  margin: 20px 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>
