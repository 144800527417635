<template>
  <div id="notifications">

    <a-button class="button" type="primary" @click="this.$refs.taskGrid.autoSizeAll()">
      Resize
    </a-button>
    <a-button class="button" type="primary" @click="removeTasks">
      Remove
    </a-button>
    <TaskGrid :roles='roles' :tasks="tasks" ref="taskGrid"/>
    <h3 :class="persistorConnected" >{{ title }}</h3>
  </div>

</template>

<script>
import {apiUrl, TLSEnabled} from "@/config/api";
import TaskGrid from "@/components/TaskGrid.vue"

export default {
  components: {
    TaskGrid
  },
  props: ['keycloak'],
  data() {
    return {
      roles: this.keycloak.resourceAccess.persistor.roles,
      socket: {},
      connected: false,
      connecting: false,
      reconnect: false,
      lastAlive: Date.now(),
      tasks: []
    }
  },
  created() {
    function reconnect(context) {
      context.connected = false
      context.connecting = false
      setTimeout(() => {
        if (!context.connecting) {
          wsConnect(context)
        }
      }, 1000)
    }
    function wsConnect(context) {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "ws" + tls + "://" + apiUrl + "/tasks/notifications"
      const token = "Bearer%20" + context.keycloak.token
      context.socket = null
      context.connecting = true
      context.socket = new WebSocket(uri, token )
      context.socket.onopen = () => {
        context.connected = true
        context.connecting = false
        context.lastAlive = Date.now()
      }
      context.socket.onmessage = (data) => {
        const item = JSON.parse(data.data)
        if (data.data === "{\"state\":\"Alive\"}") {
          context.lastAlive = Date.now()
          context.socket.send(data.data)
        }
        if ("userId" in item) {
          const datasource = context.tasks.slice()
          const index = datasource.findIndex((task => task.id === item.id))
          if (index >= 0)
            datasource[index] = item
          else
            datasource.push(item)
          context.tasks = datasource
        } else if (("notificationStatus" in item) && (item.notificationStatus === "PROCESSED")) {
          const datasource = context.tasks.slice()
          const index = datasource.findIndex((task => task.id === item.id))
          if (index >= 0) {
            datasource.splice(index, 1)
            context.tasks = datasource
          }
        }
      }

      context.socket.onclose = () => {
        reconnect(context)
      }
    }

    setInterval(() => {
      const checkTimeAlive = this.lastAlive + 90000
      if (checkTimeAlive < Date.now()) {
        if (this.connected)
          this.socket.close()
      }
    }, 30000)
    wsConnect(this)

  },
  computed: {
    title() {
      if (this.connected)
        return "Persistor Server Connected"
      else
        return "Persistor Server Disconnected"
    },
    persistorConnected () {
      if (this.connected) {
        return 'persistorStatusConnected';
      }

      return 'persistorStatusDisconnected';
    }
  },
  methods: {
    removeTasks() {
      const selectedNodes = this.$refs.taskGrid.getSelectedNodes();
      selectedNodes.map(node => {
            const message = JSON.stringify({
              id: node.data.id,
              notificationStatus: "PROCESSED"
            })
            this.socket.send(message)
          }
      )
    }
  }

}
</script>

<style>

.persistorStatusConnected {
  color: blue
}

.persistorStatusDisconnected {
  color: red
}

#app {
  margin: 60px 20px;
}

#table {
  margin-top: 20px;
}

#logout {
  float: right;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>