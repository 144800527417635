<template>
  <div id="tasks">

    <a-button class="button" type="primary" @click="this.$refs.taskGrid.autoSizeAll()">
      Resize
    </a-button>
    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>
    <a-input v-model:value="limit" :maxlength="4" placeholder="limit" style="width: 120px"/>

    <a-switch v-model:checked="refresh"  @change="this.refreshAuto()"/>
    <a-spin :indicator="indicatorRefresh" :spinning="spinning"/>

    <TaskGrid :roles='roles' :tasks="tasks" ref="taskGrid"/>
    <h3>Total items: {{ totalItems }}</h3>
  </div>

</template>

<script>

import TaskGrid from "@/components/TaskGrid.vue";
import {apiUrl, TLSEnabled} from "@/config/api";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';

export default {
  components: {
    TaskGrid
  },
  props: ['keycloak'],
  data() {
    return {
      roles: this.keycloak.resourceAccess.persistor.roles,
      tasks: [],
      totalItems: 0,
      limit: 20,
      refresh: true,
      spinning: true,
      refreshTimer: null,
      indicatorRefresh: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      })
    }
  },
  created() {
    this.refreshQuery()
    this.setTimer()
  },
  methods: {
    setTimer() {
      if (this.refreshTimer == null) {
        this.refreshTimer = setInterval(this.refreshQuery, 5000)
        this.spinning = true
      }
    },
    cancelTimer() {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
      this.spinning = false
    },
    refreshAuto() {
      if (this.refresh)
        this.setTimer()
      else
        this.cancelTimer()

    },
    refreshQuery() {
      if (!this.refresh)
        this.cancelTimer()
      function getLimit(limit) {
        if (limit)
          return "?limit=" + limit
        else
          return ""
      }
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/tasks" + getLimit(this.limit)
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.totalItems = responseJson.count
            this.tasks = responseJson.results
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }

}
</script>

<style>

#app {
  margin: 60px 20px;
}

#table {
  margin-top: 20px;
}

#logout {
  float: right;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>