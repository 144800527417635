export const apiAuthorizeUrl = 'KEYCLOAK_URL_ENV'
export const apiUrl = 'REST_API_URL_ENV'
export const authEnabled = eval("AUTH_ENABLED_KEYCLOAK_ENV")
export const TLSEnabled = eval("TLS_ENABLED_ENV")

// export const apiAuthorizeUrl = ''
// export const apiUrl = 'localhost:9001'
// export const authEnabled = false
// export const TLSEnabled = false

// export const apiAuthorizeUrl = 'https://nep.dev.gc.axesor.com/auth/'
// export const apiUrl = 'persistor.dev.gc.axesor.com'
// export const authEnabled = true
// export const TLSEnabled = true

// export const apiAuthorizeUrl = 'https://nep.pre.gc.axesor.com/auth/'
// export const apiUrl = 'persistor.pre.gc.axesor.com'
// export const authEnabled = true
// export const TLSEnabled = true
//
// export const apiAuthorizeUrl = 'https://nep.pro.gc.axesor.com/auth/'
// export const apiUrl = 'persistor.pro.gc.axesor.com'
// export const authEnabled = true
// export const TLSEnabled = true